import React, { useState } from 'react';
import ModalUnicoComponente from '../ModalUnicoComponente';
import axios from 'axios';
import secureLocalStorage  from  "react-secure-storage";


const UnicoComponente = ({componente, endpoint, stateChanger, state, ganancia}) => {
    const[openModal, setOpenModal] = useState(false);
    const[components, setComponents] = useState('');
    const[comp, setComp] = useState()

    const setComponenteUnico = (component) =>{
      setComp(component)
    }
    

    const listOfComponents = ['procesador', 'placa-madre', 'enfriador-cpu', 'caja', 'tarjeta-grafica', 'tarjeta-ram', 'almacenamiento', 'enfriador-caja', 'fuente-de-poder'];

    const url = process.env.REACT_APP_COMPONENTS_API_URL+endpoint;
    const getAllComponents = () =>{
      apiGetCall();
      return true;

      function apiGetCall(){
        axios.get(`${url}`)
        .then((response) => {
          const allComponents = response.data;
          setComponents(allComponents);
        })
        .catch(error=> console.error(`Error: ${error.response.data}`));
      }
    }

    const quitarElemento = () =>{
      setComp();
      stateChanger(!state)
    }
    

    if (comp) {
      var com = JSON.parse(comp);
      return <>
        <td className="box">
          <div className="logo-name">
            <div className="item-logo comp-img">
              <img src={com.img} className="img-responsive lazy img-fluid loaded"
                title={com.title} data-ll-status="loaded">
              </img>
            </div>
          </div>
        </td>
        <td className="comp-details">
          <div className="table_title">{com.brand} - {com.model}</div>
        </td>
        <td className="price">{com.price}
        </td>
        <td className="price"><a href={com.link} target='_blank'>LINK</a>
        </td>
        <td className="remove-product">
          <a onClick={quitarElemento} className="btn btn-danger component-remove-btn" id="p_6">Quitar
          </a>
        </td>
      </>
    }

    return <>
      <td className="select-comp" colSpan="6" >
        <a onClick={() => setOpenModal(getAllComponents(componente))} className="component-btn">
          Agregar {componente.replace(/-/g, ' ')}
        </a>
        <ModalUnicoComponente ganancia={ganancia} components={components} open={openModal} componente={componente} onClose={()=>setOpenModal(false)} setComponenteUnico={setComponenteUnico} />
      </td>
    </>
  }

  export default UnicoComponente;
  