import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Div from '../Div';
import '../Cta/cta.scss'
import Button from '../Button';
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';
import ComponentePC from '../ComponentePC';
import ComponenteTabla from '../ComponenteTabla'
import OrderCard from '../OrderCard'
import ModalOrden from '../ModalOrden'
import { Modal } from 'bootstrap';

const listOfComponents = [
  {
    componentName: 'procesador',
    componentEndpoint: 'processor',
    componentImage: '/images/bgcomponents/procesador.webp'
  },
  {
    componentName: 'placa-madre',
    componentEndpoint: 'motherboard',
    componentImage: '/images/bgcomponents/placamadre.webp'
  },
  {
    componentName: 'enfriador-cpu',
    componentEndpoint: 'cpu_fan',
    componentImage: '/images/bgcomponents/cpufan.webp'
  },
  {
    componentName: 'caja',
    componentEndpoint: 'case',
    componentImage: '/images/bgcomponents/caja.webp'
  },
  {
    componentName: 'tarjeta-grafica',
    componentEndpoint: 'gpu',
    componentImage: '/images/bgcomponents/gpu.webp'
  },
  {
    componentName: 'tarjeta-ram',
    componentEndpoint: 'ram',
    componentImage: '/images/bgcomponents/ram.webp'
  },
  {
    componentName: 'almacenamiento',
    componentEndpoint: 'storage',
    componentImage: '/images/bgcomponents/almacenamiento.webp'
  },
  {
    componentName: 'enfriador-caja',
    componentEndpoint: 'case_fan',
    componentImage: '/images/bgcomponents/fans.webp'
  },
  {
    componentName: 'fuente-de-poder',
    componentEndpoint: 'powersupply',
    componentImage: '/images/bgcomponents/psu.webp'
  },
];

function Crear() {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0)
  const [modalOrdenOpen, setModalOrdenOpen] = useState(false)
  // const ganancia = 1.4
  const ganancia = 1

  const updateTotalPrice = () =>{
    var total = 0;

    for (var i in listOfComponents) {
      var item = JSON.parse(secureLocalStorage.getItem(listOfComponents[i].componentName))
      if (item) {
        total += item.price
      }
    }
    if(total!==0) total*=ganancia
    setTotalPrice(total)
  }
  pageTitle('Crear');
  useEffect(() => {
    window.scrollTo(0, 0);
    updateTotalPrice();
  }, []);

  function OrdenDeCompra() {
    const createOrder = (orderRequest) => {
      //print json of order
      // console.log(JSON.stringify(orderRequest));
      axios.
        post(
          process.env.REACT_APP_COMPONENTS_API_URL + 'createOrder',
          orderRequest
        )
        .then(response => {
          // console.log(response.data);
          for (var i in listOfComponents) {
            if(JSON.parse(secureLocalStorage.getItem(listOfComponents[i].componentName)).id !== 'noItem'){
              // window.open(JSON.parse(secureLocalStorage.getItem(listOfComponents[i].componentName)).link, '_blank')
            }
            // secureLocalStorage.removeItem(listOfComponents[i].componentName)
            // secureLocalStorage.removeItem('p' + listOfComponents[i].componentName)
          }
          // navigate('/ordenes')
        })
        .catch(error => {
          console.error(error.response.data);
        });
    }
    if (!isAuthenticated) {
      alert('Debes loguearte para poder realizar un pedido')
      return
    }
    if (!user.email_verified) {
      alert('Revisa tu email ' + user.email + ' y verifica tu cuenta antes de realizar un pedido')
      return
    }

    var items = [];
    for (var i in listOfComponents) {
      items[listOfComponents[i].componentName] = JSON.parse(secureLocalStorage.getItem(listOfComponents[i].componentName));
    }

    for (var index in items) {
      if (!items[index]) {
        alert('Debes asegurarte de agregar todos los componentes');
        break;
      }
      if (index === 'fuente-de-poder') {
        var computador = {};
        computador = {
          'procesador': JSON.parse(secureLocalStorage.getItem('procesador')),
          'placa-madre': JSON.parse(secureLocalStorage.getItem('placa-madre')),
          'enfriador-cpu': JSON.parse(secureLocalStorage.getItem('enfriador-cpu')),
          'caja': JSON.parse(secureLocalStorage.getItem('caja')),
          'tarjeta-grafica': JSON.parse(secureLocalStorage.getItem('tarjeta-grafica')),
          'tarjeta-ram': JSON.parse(secureLocalStorage.getItem('tarjeta-ram')),
          'almacenamiento': JSON.parse(secureLocalStorage.getItem('almacenamiento')),
          'enfriador-caja': JSON.parse(secureLocalStorage.getItem('enfriador-caja')),
          'fuente-de-poder': JSON.parse(secureLocalStorage.getItem('fuente-de-poder')),
        }
        var order = {
          'cliente': (user),
          'computador': (computador)
        };
        // createOrder(order);
        setModalOrdenOpen(true)
        console.log(JSON.stringify(order));
      }
    }
  }

  return (
    <>

      <ModalOrden listOfComponents={listOfComponents} open={modalOrdenOpen} onClose={()=>setModalOrdenOpen(false)}></ModalOrden>
      {/* Mostrar componentes con cards */}
      <Spacing lg="125" md="80" />
      <Div className="container">
        <SectionHeading
          title="Selecciona los Componentes"
          subtitle="Crear"
          variant="cs-style1 text-center"
        />
        <Spacing lg="35" md="45" />
        <Div className="row">
          {listOfComponents.map((item, index) => (
            <Div key={index} className="col-lg-4 col-sm-6">
              <ComponentePC
                imagen={item.componentImage}
                componente={item.componentName}
                endpoint={item.componentEndpoint}
                updateTotalPrice={updateTotalPrice}
                ganancia={ganancia}
              />
              <Spacing lg="35" md="10" />
            </Div>
          ))}
        </Div>
        <Spacing lg="10" md="10" />
       

      </Div>
      <OrderCard totalPrice={totalPrice} OrdenDeCompra={OrdenDeCompra}/>
      <div className="order-card" style={{marginTop:'16px'}}>
<p className="order-card__title">Notas</p>
<p className="order-card__price">Precios solo para referencias</p>
<p className="order-card__price">Debes dar permiso para abrir multiples pestañas</p>
</div>

      {/* Tabla para seleccionar componentes */}
      {/* <table className="table-crear table-design">
        <thead>
          <tr className="thead-crear">
            <th scope="col" width="15%" style={{ border: 'none' }}></th>
            <th scope="col" width="15%" style={{ border: 'none' }}></th>
            <th scope="col" width="50%" style={{ border: 'none' }}></th>
            <th scope="col" width="10%" style={{ border: 'none' }}></th>
            <th scope="col" width="10%" style={{ border: 'none' }}></th>
          </tr>
        </thead>
        <tbody className="tbody-crear">
        {listOfComponents.map((item, index) => (
          <tr className="items" key={index}>
          <ComponenteTabla stateChanger={setState} state={state} componente={item.componentName} endpoint={item.componentEndpoint} />
        </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <Div className='container'>
        <Div onClick={OrdenDeCompra} style={{ display: 'inline' }}>
          <Button btnText={'Continuar con metodo de envio'} style={{ backgroundColor: 'black' }} />
        </Div>
      </Div> */}
    </>
  );
}

export default Crear;
