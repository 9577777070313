import React, { useState } from 'react';
import './componentepc.scss'
import { Link } from 'react-router-dom';
import Div from '../Div';
import ModalComp from '../ModalComp';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";

const vacio = {
  id: 'noItem',
  title: 'N/A',
  link: 'N/A',
  img: '/images/noItem.webp',
  model: 'N/A',
  priceCTP: 0,
  price: 0,
};


export default function ComponentePC({ componente, imagen, endpoint, stateChanger, state, updateTotalPrice, ganancia }) {
  const [openModal, setOpenModal] = useState(false);
  const [components, setComponents] = useState('');

  const listOfComponents = ['procesador', 'placa-madre', 'enfriador-cpu', 'caja', 'tarjeta-grafica', 'tarjeta-ram', 'almacenamiento', 'enfriador-caja', 'fuente-de-poder'];

  const url = process.env.REACT_APP_COMPONENTS_API_URL + endpoint;
  const getAllComponents = () => {
    let indexComponente = listOfComponents.indexOf(componente);
    let componenteAnterior = listOfComponents[indexComponente - 1];
    let p = '';
    if (indexComponente != 0) {
      if (secureLocalStorage.getItem(componenteAnterior)) {
        if (secureLocalStorage.getItem('p' + componente)) {
          p = '?p=' + secureLocalStorage.getItem('p' + componente);
        }
      } else {
        alert('debes seleccionar el componente anterior')
        return false
      }
    }
    apiGetCall();
    return true;

    function apiGetCall() {
      axios.get(`${url}${p}`)
        .then((response) => {
          const allComponents = response.data;
          allComponents.unshift(vacio);
          setComponents(allComponents);
        })
        .catch(error => console.error(`Error: ${error.response.data}`));
    }
  }

  const quitarElemento = () => {
    // let indexComponente = listOfComponents.indexOf(componente);
    // for (indexComponente; indexComponente < listOfComponents.length; indexComponente++) {
    //   secureLocalStorage.removeItem(listOfComponents[indexComponente]);
    // }
    secureLocalStorage.removeItem(componente);
    if (componente == 'procesador') {
      secureLocalStorage.removeItem('placa-madre');
      secureLocalStorage.removeItem('pplaca-madre');

      secureLocalStorage.removeItem('enfriador-cpu');
      secureLocalStorage.removeItem('penfriador-cpu');

      //como se elimina placa madre, tambien hay que eliminar estos
      secureLocalStorage.removeItem('tarjeta-ram');
      secureLocalStorage.removeItem('ptarjeta-ram');
      secureLocalStorage.removeItem('caja')
      secureLocalStorage.removeItem('pcaja')
    }
    if (componente == 'placa-madre') {
      secureLocalStorage.removeItem('tarjeta-ram');
      secureLocalStorage.removeItem('ptarjeta-ram');
      secureLocalStorage.removeItem('caja')
      secureLocalStorage.removeItem('pcaja')
    }
    if (state) {
      stateChanger = false;
    }
    else {
      stateChanger = true;
    }
    updateTotalPrice();
  }

  if (secureLocalStorage.getItem(componente)) {
    var com = JSON.parse(secureLocalStorage.getItem(componente));
    return <>
      <Div className="cs-team cs-style1">
        <Div className="cs-member_thumb">
          <img src={'/images/bgcomponents/negro.webp'} alt={componente} />
          <Div className="cs-member_overlay" />
        </Div>
        <Div className="cs-member_info">
          <h2 className="cs-member_name">
            <div onClick={quitarElemento} style={{ display: 'inline-block' }}>
              <Link >{'ELIMINAR'}</Link>
            </div>
          </h2>
          <Div className="cs-member_designation" style={{ textTransform: 'uppercase' }}>{componente.replace(/-/g, ' ')}</Div>
          <h4 style={{ marginBottom: '5px', color: '#ec6090' }}>{com.priceCTP}$</h4>
          <h4 className='truncar-2'>{com.title}</h4>
          <div>
            <img src={com.img} className="img-responsive lazy img-fluid loaded" width={'70px'}
              title={com.title} data-ll-status="loaded">
            </img>
          </div>
        </Div>
      </Div>
    </>
  }

  return (<>
    <ModalComp components={components} open={openModal} updateTotalPrice={updateTotalPrice} ganancia={ganancia} componente={componente} onClose={() => setOpenModal(false) } />
    <Div className="cs-team cs-style1" onClick={() => setOpenModal(getAllComponents(componente))} style={{ cursor: 'pointer' }}>

      <Div className="cs-member_thumb">
        <img src={imagen} alt={componente} />
        <Div className="cs-member_overlay" />
      </Div>
      <Div className="cs-member_info">
        <h5 style={{ textTransform: 'uppercase' }}>{componente.replace(/-/g, ' ')}</h5>
        {/* <Div className="cs-member_designation" style={{ textTransform: 'uppercase' }}>{componente.replace(/-/g, ' ')}</Div>
        <h6 className="cs-member_name">
          <Link onClick={() => setOpenModal(getAllComponents(componente))}>{'AGREGAR'}</Link>
        </h6> */}
      </Div>
    </Div>
  </>
  )
}
