import React, { useEffect, useState } from 'react';
import './modalunicocomponente.scss'
import secureLocalStorage from "react-secure-storage";

function ModalUnicoComponente({ components, open, componente, onClose, ganancia, setComponenteUnico }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    

    function setComponent({ index }) {
        var component = ''
        component = JSON.stringify(searchResults[index]);
        setComponenteUnico(component);
    }

    useEffect(() => {
        if (components) {
            const filteredResults = searchTerm
                ? components.filter(item =>
                    item.title.toLowerCase().includes(searchTerm.toLowerCase())
                )
                : components;
            setSearchResults(filteredResults);
        }
    }, [searchTerm, components]);


    if (!open) return null;
    return (<>
        <div className="modal fade show" id="exampleModalScrollable" tabIndex="-1" aria-labelledby="exampleModalScrollableTitle"
            style={{ display: 'block' }} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalScrollableTitle">{componente.replace('-', ' ')}</h5>
                        <input className='input-buscar' style={{ color: 'black' }} type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Buscar" />
                        <button onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {
                        (() => {
                            if (searchResults) {
                                if (!components) {
                                    return (
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <img style={{ margin: '5% auto' }} src='/images/cargando.gif' alt='gif cargando'></img>
                                        </div>
                                    )
                                }
                            }
                            return (<>

                                <div className="modal-body">
                                    <table id="myTable" className="mt-3 table table-design table-comp">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="15%">Imagen</th>
                                                <th scope="col" width="50%">Titulo</th>
                                                <th scope="col" width="10%">Precio</th>
                                                <th scope="col" width="10%">Agregar</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                (() => {
                                                    return (
                                                        searchResults.map((el, index) => (
                                                            <tr key={el.id} className="items" data-href="#">
                                                                <td className='comp-img'><img src={el.img} className="img-responsive lazy img-fluid loaded" title="titulo" data-ll-status="loaded" alt='img comp'></img></td>
                                                                <td className='comp-title'>{el.title}</td>
                                                                <td className="price comp-price">{(el.price * ganancia).toFixed()}</td>
                                                                <td className="comp-btn">
                                                                    <a onClick={
                                                                        () => {
                                                                            setComponent({ index });
                                                                            onClose();
                                                                        }

                                                                    }
                                                                        className="btn btn-danger component-add-btn" id="p_6">+</a></td>
                                                            </tr>

                                                        ))
                                                    )

                                                })()

                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </>
                            )
                        })()

                    }
                    <div className="modal-footer">
                        <button onClick={onClose} type="button" className="btn btn-secondary"
                            data-bs-dismiss="modal">Close
                        </button>
                    </div>
                </div >
            </div >
        </div >
    </>
    );
};

export default ModalUnicoComponente;