import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: '99999',
    color: 'black'
  },
  modal: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 4,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 20,
    color:'black'
  },
  content: {
    fontSize: 16,
    marginBottom: 20,
    color:'black'
  },
  button: {
    backgroundColor: "#ec6090!important",
    color: "white",
    padding: "10px 20px",
    borderRadius: 4,
    outline: "none",
    border: "none",
    cursor: "pointer",
  },
});

const Link = ({comp}) =>{
  const c = JSON.parse(secureLocalStorage.getItem(comp))
  if(c.id === 'noItem'){
    return;
  }
  return (<li><a href={c.link} target="_blank">{c.brand} - {c.model}</a></li>)
}


const ModalOrden = ({ onClose, open, listOfComponents }) => {
  const classes = useStyles();

  if (!open) return null;
  return (
    <Box className={classes.root}>
      <Box className={classes.modal}>
        <Typography variant="h5" className={classes.title} style={{marginBottom:'16px'}}>
          Link de tus componentes
        </Typography>
          <ul>
           {listOfComponents.map((item, index)=>(
            <Link key={index} comp={listOfComponents[index].componentName}/>
           ))}
          </ul>
        <Button onClick={onClose} variant="contained" className={classes.button}>
          OK
        </Button>
      </Box>
    </Box>
  );
};

export default ModalOrden;