import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import Crear from './components/Pages/Crear';
import Ordenes from './components/Pages/Ordenes';
import Componentes from './components/Pages/Componentes';
import ComponenteUnico from './components/Pages/ComponenteUnico';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import TeamPage from './components/Pages/TeamPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import TeamDetails from './components/Pages/TeamDetails';
import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
import CreativePortfolioHome from './components/Pages/CreativePortfolioHome';
import DigitalAgencyHome from './components/Pages/DigitalAgencyHome';
import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome';
import CaseStudyShowcaseHome from './components/Pages/CaseStudyShowcaseHome';
import PreloadImages from './components/PreloadImages';
import Layout from './components/Layout';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
import FaqPage from './components/Pages/FaqPage';

const listOfImages=[
'/images/bgcomponents/procesador.webp',
'/images/bgcomponents/placamadre.webp',
'/images/bgcomponents/cpufan.webp',
'/images/bgcomponents/caja.webp',
'/images/bgcomponents/gpu.webp',
'/images/bgcomponents/ram.webp',
'/images/bgcomponents/almacenamiento.webp',
'/images/bgcomponents/fans.webp',
'/images/bgcomponents/psu.webp',
'/images/noItem.webp',
'/images/hero_bg.webp',
"/images/dragonbreathpc.webp",
"/images/pewpewpc.webp",
"/images/godzillapc.webp",
"/images/zombieslayerpc.webp",
'/images/bgcomponents/negro.webp'
]

function App() {
  return (
    <>
    <PreloadImages sources={listOfImages} />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/crear" element={<Crear />} />
          <Route path="/ordenes" element={<Ordenes />} />
          <Route path="/componentes" element={<Componentes />} />
          <Route path="/componentes/:componente" element={<ComponenteUnico />} />

          {/* <Route
            path="photography-agency"
            element={<PhotographyAgencyHome />}
          />
          <Route path="digital-agency" element={<DigitalAgencyHome />} />
          <Route path="marketing-agency" element={<MarketingAgencyHome />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team/:teamDetails" element={<TeamDetails />} />
          <Route
            path="/case-study/:caseStudyDetailsId"
            element={<CaseStudyDetailsPage />}
          />
          <Route path="faq" element={<FaqPage />} /> */}
        </Route>
        {/* <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          <Route
            path="creative-portfolio"
            element={<CreativePortfolioHome />}
          />
          <Route
            path="showcase-portfolio"
            element={<ShowcasePortfolioHome />}
          />
          <Route
            path="case-study-showcase"
            element={<CaseStudyShowcaseHome />}
          />
        </Route> */}
        <Route path="*" element={<ErrorPage />} /> 
      </Routes>
    </>
  );
}

export default App;
