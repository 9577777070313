import React from 'react';
import axios from 'axios';

export default function Newsletter({ title, subtitle, placeholder }) {
  const emailInput = React.createRef();

  function verificarEmail() {
    const email = emailInput.current.value;
    const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

    if (isValid) {
      const saveEmail = (email) => {
        axios
          .post(
            process.env.REACT_APP_COMPONENTS_API_URL + 'saveEmail',
            email
          )
          .then((response) => {
            // console.log(response.data);
          })
          .catch((error) => {
            console.error(error.response.data);
          });
          emailInput.current.value = ''
      };

      saveEmail({ email });
    } else {
      // console.log('Email is invalid');
    }
  }

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <div className="cs-newsletter cs-style1">
        <div className="cs-newsletter_form">
          <input
            type="email"
            className="cs-newsletter_input"
            placeholder={placeholder}
            ref={emailInput}
          />
          <button onClick={verificarEmail} className="cs-newsletter_btn">
            <span>Enviar</span>
          </button>
        </div>
        <div className="cs-newsletter_text">{subtitle}</div>
      </div>
    </>
  );
}
