import React, { useEffect } from 'react';

const PreloadImages = ({ sources }) => {
  useEffect(() => {
    const images = sources.map((source) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = source;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(images)
      // .then(() => console.log('All images preloaded'))
      .catch((err) => console.log('Error preloading images:', err));
  }, [sources]);

  return null;
};

export default PreloadImages;