import React from "react";
import './OrderCard.scss';
import Div from '../Div';
import Button from '../Button';

export default function OrderCard({totalPrice, OrdenDeCompra}){
return (
<div className="order-card-contenedora">
<div className="order-card">
<p className="order-card__title">Resumen del pedido</p>
<p className="order-card__price">{totalPrice.toFixed(2)}</p>
</div>
<Div onClick={OrdenDeCompra} style={{ display: 'inline' }}>
<Button btnText={'Ver Links'} style={{ backgroundColor: 'black' }} />
</Div>
</div>
)
}