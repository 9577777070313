import React, { useEffect, useState } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import secureLocalStorage from 'react-secure-storage';

export default function Home() {
  pageTitle('Home');
  const [frontendPrebuilts, setFrontendPrebuilts] = useState([]);


  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Behance',
      links: '/',
    },
    {
      name: 'Twitter',
      links: '/',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Componentes',
      factNumber: '1600',
    },
    {
      title: 'Procesadores',
      factNumber: '170',
    },
    {
      title: 'Placas madre',
      factNumber: '185',
    },
    {
      title: 'Tarjetas graficas',
      factNumber: '150',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchFrontPrebuilts = async () => {
      const res = await fetch(process.env.REACT_APP_COMPONENTS_API_URL + "getFrontendPrebuilts");
      const data = await res.json();
      setFrontendPrebuilts(data.data);
    }
    fetchFrontPrebuilts();
  }, []);

  function setPrebuiltPC(prebuiltPC) {
    secureLocalStorage.setItem('procesador', frontendPrebuilts[prebuiltPC].computer_processor)
    secureLocalStorage.setItem('placa-madre', frontendPrebuilts[prebuiltPC].computer_motherboard)
    secureLocalStorage.setItem('enfriador-cpu', frontendPrebuilts[prebuiltPC].computer_cpu_cooler)
    secureLocalStorage.setItem('caja', frontendPrebuilts[prebuiltPC].computer_case)
    secureLocalStorage.setItem('tarjeta-grafica', frontendPrebuilts[prebuiltPC].computer_graphics_card)
    secureLocalStorage.setItem('tarjeta-ram', frontendPrebuilts[prebuiltPC].computer_ram)
    secureLocalStorage.setItem('almacenamiento', frontendPrebuilts[prebuiltPC].computer_storage)
    secureLocalStorage.setItem('enfriador-caja', frontendPrebuilts[prebuiltPC].computer_case_cooler)
    secureLocalStorage.setItem('fuente-de-poder', frontendPrebuilts[prebuiltPC].computer_power_supply)
  }

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="ENCUENTRA LOS <br/><span style='color:#ec6090'>MEJORES</span> <br/>COMPONENTES"
        // subtitle="We deliver best problem solving solution for our client and provide finest finishing product in present and upcoming future."
        btnText="Crea Tu PC"
        btnLink="/crear"
        scrollDownId="#prebuilts"
        // socialLinksHeading="Follow Us"
        // heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.webp"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Compatibilidad"
          subtitle="Despreocupate por verificar si los componentes son compatibles, nuestro creador de PCs se encargara de eso a medida que vayas agregando las piezas."
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="prebuilts">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="PCs diseñadas para tu presupuesto"
                subtitle="Elige tu PC"
                // btnText="Ver todas las PCs"
                // btnLink="/prebuilts"
                btnText="O créala tu mismo"
                btnLink="/crear"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <button onClick={() => { setPrebuiltPC(2) }} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <Card
                      title={frontendPrebuilts[2]?.computer_name}
                      link="/crear"
                      src="/images/dragonbreathpc.webp"
                      alt="PC2"
                    />
                  </button>
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <button onClick={() => { setPrebuiltPC(0) }} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <Card
                      title={frontendPrebuilts[0]?.computer_name}
                      link="/crear"
                      src="/images/pewpewpc.webp"
                      alt="PC2"
                    />
                  </button>
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <button onClick={() => { setPrebuiltPC(3) }} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <Card
                      title={frontendPrebuilts[3]?.computer_name}
                      link="/crear"
                      src="/images/godzillapc.webp"
                      alt="PC3"
                    />
                  </button>
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <button onClick={() => { setPrebuiltPC(1) }} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <Card
                      title={frontendPrebuilts[1]?.computer_name}
                      link="/crear"
                      src="/images/zombieslayerpc.webp"
                      alt="PC4"
                    />
                  </button>
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section 
      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Portfolio to explore"
            subtitle="Latest Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div>
      {/* End Portfolio Section */}


      {/* Start Awards Section 
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="We get multiple awards"
                subtitle="Our Awards"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Awards Section */}

      {/* Start Video Block Section 
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Our agile process is ability to adapt and respond to change. Agile
          organizations view change as an opportunity, not a threat.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        />
      </Div>
      {/* End Video Block Section */}

      {/* Start Team Section
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End Team Section */}

      {/* Start Testimonial Section
      <TestimonialSlider />
      {/* End Testimonial Section */}

      {/* Start Blog Section
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Blog Section */}

      {/* Start MovingText Section
      <Spacing lg="125" md="70" />
      <MovingText text="Our reputed world wide partners" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section 
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
