import React, { useState } from 'react';
import './comppc.scss'
import { Link } from 'react-router-dom';
import Div from '../Div';
import ModalComp from '../ModalComp';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

export default function CompPC({ componente, imagen, endpoint, stateChanger, state }) {
  const [openModal, setOpenModal] = useState(false);
  const [components, setComponents] = useState('');
  const navigate = useNavigate();
  
  return (<>
    <Div className="cs-team cs-style1" onClick={() => navigate(`${componente}`)} style={{ cursor: 'pointer' }}>
      <Div className="cs-member_thumb">
        <img src={imagen} alt={componente} />
        <Div className="cs-member_overlay" />
      </Div>
      <Div className="cs-member_info">
        <h5 style={{ textTransform: 'uppercase' }}>{componente.replace(/-/g, ' ')}</h5>
      </Div>
    </Div>
  </>
  )
}
