import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Div from '../Div';
import '../Cta/cta.scss'
import Button from '../Button';
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';
import CompPC from '../CompPC';
import ComponenteTabla from '../ComponenteTabla'
import OrderCard from '../OrderCard'

const listOfComponents = [
  {
    componentName: 'procesador',
    componentEndpoint: 'processor',
    componentImage: '/images/bgcomponents/procesador.webp'
  },
  {
    componentName: 'placa-madre',
    componentEndpoint: 'motherboard',
    componentImage: '/images/bgcomponents/placamadre.webp'
  },
  {
    componentName: 'enfriador-cpu',
    componentEndpoint: 'cpu_fan',
    componentImage: '/images/bgcomponents/cpufan.webp'
  },
  {
    componentName: 'caja',
    componentEndpoint: 'case',
    componentImage: '/images/bgcomponents/caja.webp'
  },
  {
    componentName: 'tarjeta-grafica',
    componentEndpoint: 'gpu',
    componentImage: '/images/bgcomponents/gpu.webp'
  },
  {
    componentName: 'tarjeta-ram',
    componentEndpoint: 'ram',
    componentImage: '/images/bgcomponents/ram.webp'
  },
  {
    componentName: 'almacenamiento',
    componentEndpoint: 'storage',
    componentImage: '/images/bgcomponents/almacenamiento.webp'
  },
  {
    componentName: 'enfriador-caja',
    componentEndpoint: 'case_fan',
    componentImage: '/images/bgcomponents/fans.webp'
  },
  {
    componentName: 'fuente-de-poder',
    componentEndpoint: 'powersupply',
    componentImage: '/images/bgcomponents/psu.webp'
  },
];

function Componentes() {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const [state, setState] = useState(false);

  pageTitle('Componentes');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      {/* Mostrar componentes con cards */}
      <Spacing lg="125" md="80" />
      <Div className="container">
        <SectionHeading
          title="Componentes"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="35" md="45" />
        <Div className="row">
          {listOfComponents.map((item, index) => (
            <Div key={index} className="col-lg-2 col-sm-6">
              <CompPC
                imagen={item.componentImage}
                componente={item.componentName}
                endpoint={item.componentEndpoint}
              />
              <Spacing lg="35" md="10" />
            </Div>
          ))}
        </Div>
        <Spacing lg="10" md="10" />
      </Div>

    </>
  );
}

export default Componentes;
