import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Div from '../Div'
import ErrorPage from '../Pages/ErrorPage';
import { pageTitle } from '../../helper';
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';
import OrderCard from '../OrderCard';
import UnicoComponente from '../UnicoComponente';

import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import '../Cta/cta.scss'
import Button from '../Button';
import ComponentePC from '../ComponentePC';
import ComponenteTabla from '../ComponenteTabla'
import ModalOrden from '../ModalOrden'
import { Modal } from 'bootstrap';

const listOfComponents = [
    {
        componentName: 'procesador',
        componentEndpoint: 'processor',
        componentImage: '/images/bgcomponents/procesador.webp'
    },
    {
        componentName: 'placa-madre',
        componentEndpoint: 'motherboard',
        componentImage: '/images/bgcomponents/placamadre.webp'
    },
    {
        componentName: 'enfriador-cpu',
        componentEndpoint: 'cpu_fan',
        componentImage: '/images/bgcomponents/cpufan.webp'
    },
    {
        componentName: 'caja',
        componentEndpoint: 'case',
        componentImage: '/images/bgcomponents/caja.webp'
    },
    {
        componentName: 'tarjeta-grafica',
        componentEndpoint: 'gpu',
        componentImage: '/images/bgcomponents/gpu.webp'
    },
    {
        componentName: 'tarjeta-ram',
        componentEndpoint: 'ram',
        componentImage: '/images/bgcomponents/ram.webp'
    },
    {
        componentName: 'almacenamiento',
        componentEndpoint: 'storage',
        componentImage: '/images/bgcomponents/almacenamiento.webp'
    },
    {
        componentName: 'enfriador-caja',
        componentEndpoint: 'case_fan',
        componentImage: '/images/bgcomponents/fans.webp'
    },
    {
        componentName: 'fuente-de-poder',
        componentEndpoint: 'powersupply',
        componentImage: '/images/bgcomponents/psu.webp'
    },
];


export default function ComponenteUnico() {
    pageTitle('Componente');
    const navigate = useNavigate();
    const [state, setState] = useState(false);
    const [componentesAgregados, setComponentesAgregados] = useState([]);
    const { componente } = useParams()
    const index = listOfComponents.findIndex(component => component.componentName === componente);
    const ganancia = 1;

    function agregarComponente() {
        setComponentesAgregados([...componentesAgregados, { id: componentesAgregados.length }]);
    }

    function isValido() {
        if (index === -1) {
            return false;
        }
        return true;
    }

    if (!isValido()) return (
        <ErrorPage />
    )

    return (
        <>

            <Spacing lg="125" md="80" />
            <Div className="container">
                <SectionHeading
                    title={componente.replace(/-/g, ' ').toUpperCase()}
                    subtitle=""
                    variant="cs-style1 text-center"
                />

                <table className="table-crear table-design" style={{ marginTop: '0px' }}>
                    <thead>
                        <tr className="thead-crear">
                            <th scope="col" width="15%" style={{ border: 'none' }}></th>
                            <th scope="col" width="50%" style={{ border: 'none' }}></th>
                            <th scope="col" width="10%" style={{ border: 'none' }}></th>
                            <th scope="col" width="15%" style={{ border: 'none' }}></th>
                            <th scope="col" width="10%" style={{ border: 'none' }}></th>
                        </tr>
                    </thead>
                    <tbody className="tbody-crear">
                        <tr className="items" key={componente.id}>
                            <UnicoComponente
                                stateChanger={setState}
                                state={state}
                                componente={listOfComponents[index].componentName}
                                endpoint={listOfComponents[index].componentEndpoint}
                                ganancia={ganancia}
                            />
                        </tr>
                        {componentesAgregados.map(componente => (
                            <tr className="items" key={componente.id}>
                                <UnicoComponente
                                    stateChanger={setState}
                                    state={state}
                                    componente={listOfComponents[index].componentName}
                                    endpoint={listOfComponents[index].componentEndpoint}
                                    ganancia={ganancia}
                                />
                            </tr>
                        ))}

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <Div onClick={agregarComponente} style={{ display: 'flex', justifyContent:'center' }}>
                    <Button btnText={'Agregar otro'} style={{ backgroundColor: 'black' }} />
                </Div>
                <Spacing lg="10" md="10" />
            </Div>
        </>
    )
}